<template>
  <v-row>
    <v-col
      v-if="materialsData.length > 0"
      cols="12"
      sm="6"
      md="2"
    >
      <statistics-card-vertical-no-dots
        color="success"
        :icon="icons.mdiCurrencyUsd"
        :statistics="formatRevenue(soldRevenue)"
        stat-title="Revenue"
        subtitle="All-time Revenue"
      ></statistics-card-vertical-no-dots>
    </v-col>
    <v-col
      v-if="statisticsData.length > 0"
      cols="12"
      md="5"
      sm="12"
    >
      <material-statistics-card :statsdata="statisticsData"></material-statistics-card>
    </v-col>
    <v-col
      v-if="statisticsType.length > 0"
      cols="12"
      md="5"
      sm="12"
    >
      <material-sales-overview
        :statstype="statisticsType"
      ></material-sales-overview>
    </v-col>
  </v-row>
</template>

<script>
import StatisticsCardVerticalNoDots from '@/@core/components/statistics-card/StatisticsCardVerticalNoDots.vue'
import store from '@/store'
import { mdiCurrencyUsd } from '@mdi/js'
import { onUnmounted, ref } from 'vue'
import currency from 'currency.js'
import MaterialSalesOverview from './MaterialSalesOverview.vue'
import MaterialStatisticsCard from './MaterialStatisticsCard.vue'
// eslint-disable-next-line object-curly-newline

// import StatisticsCardLineChart from '@core/components/statistics-card/StatisticsCardLineChart.vue'
// import StatisticsCardRadialBarChart from '@core/components/statistics-card/StatisticsCardRadialBarChart.vue'

import materialStoreModule from '../../apps/material/materialStoreModule'

export default {
  components: {
    StatisticsCardVerticalNoDots,

    // StatisticsCardLineChart,
    // StatisticsCardRadialBarChart,
    MaterialStatisticsCard,
    MaterialSalesOverview,
  },
  setup() {
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })

    const materialsData = ref([])
    const soldRevenue = ref(0)
    const statisticsData = ref([
      {
        title: 'Published',
        total: 0,
      },
      {
        title: 'Sold',
        total: 0,
      },
      {
        title: 'Total',
        total: 0,
      },
    ])
    const statisticsType = ref([])

    const setStats = () => {
      materialsData.value.forEach((m, idx) => {
        const theTypeIdx = statisticsType.value.findIndex(t => t.name === m.type)
        if (theTypeIdx < 0) {
          statisticsType.value.push({
            name: m.type,
            count: 0,
          })
        } else {
          statisticsType.value[theTypeIdx].count += 1
        }

        // Published Count
        if (m.published) {
          statisticsData.value[0].total += 1
        }

        // Sold Count
        if (m.sold) {
          statisticsData.value[1].total += 1
          soldRevenue.value += parseFloat(m.price)
        }
        statisticsData.value[2].total += 1
      })
    }

    const formatRevenue = revenue => currency(revenue, { precision: 2 }).format()

    const materials = localStorage.getItem('materials-list')
    if (materials === undefined || materials === null) {
      store
        .dispatch('app-material/fetchMaterialsByUser')
        .then(response => {
          materialsData.value = response
          setStats()
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      materialsData.value = JSON.parse(materials)
      setStats()
    }

    return {
      soldRevenue,
      formatRevenue,
      materialsData,
      statisticsData,
      statisticsType,
      StatisticsCardVerticalNoDots,
      MaterialStatisticsCard,
      MaterialSalesOverview,
      icons: {
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
